/**
* Initialize gtm cookie
*
*/
function setFirstCookie() {
    const domain = window.location.hostname;
    if (!domain) return;

    const cookieName = 'very_first_cookie';
    const expiryDays = 395; // 13 months;
    const path = '/';
    const secure = true;

    const reworkedDomain = '.' + domain.replace(/^www\./, '');

    // Check if the cookie already exists
    if (
        !document.cookie
            .split('; ')
            .some((cookie) => cookie.startsWith(`${cookieName}=`))
    ) {
        const value = `${Date.now()}`;

        const expires = new Date();
        expires.setTime(expires.getTime() + (expiryDays * 24 * 60 * 60 * 1000));

        // Set the cookie
        document.cookie = `${cookieName}=${value}; expires=${expires.toUTCString()}; path=${path}; domain=${reworkedDomain};${
            secure ? ' Secure;' : ''
        } SameSite=None;`;
    } else {
        // If the cookie exists, refresh its expiration

        // const currentValue = document.cookie
        //     .split('; ')
        //     .find((cookie) => cookie.startsWith(`${cookieName}=`))
        //     .split('=')[1];

        // const expires = new Date();
        // expires.setTime(expires.getTime() + (expiryDays * 24 * 60 * 60 * 1000));

        // document.cookie = `${cookieName}=${currentValue}; expires=${expires.toUTCString()}; path=${path}; domain=${reworkedDomain};${
        //     secure ? ' Secure;' : ''
        // } SameSite=None;`;
    }
}


$(document).ready(function () {
    setFirstCookie();
});
